

export default function EmptyFrame() {
    return(
        <img
                className="frame"
                src='./img/frame.png'
                alt="frame"
            />
    )
}